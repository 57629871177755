<template>
  <div class="webpage">
    <img src="../../assets/product/webpage/1.png" class="headerimg" />
    <div class="contertop perplex">
      <div class="title">您是否有以下困扰？</div>
      <div class="advantagelist">
        <div class="advantageimg">
          <img src="../../assets/product/webpage/2.png" alt="" />
        </div>
        <div class="advantagecard">
          <div class="advantageline">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">在线交易少</div>
              <div class="cardtext">
                据统计，遇到不信任网站时，92.6%的用户回取消交易
              </div>
            </div>
          </div>

          <div class="advantageline topandbottom">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">客户不信任</div>
              <div class="cardtext">网站没有任何可信标志，企业实力遭质疑</div>
            </div>
          </div>

          <div class="advantageline topandbottom">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">网站被拦截</div>
              <div class="cardtext">网站被浏览器、安装管家拦截，用户打不开</div>
            </div>
          </div>

          <div class="advantageline">
            <div class="cardimg"></div>
            <div class="advantageinfo">
              <div class="advantagetitle">网站不正常</div>
              <div class="cardtext">
                网站页面打开速度慢，或者页面被篡改，用户进不去
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contertop material">
      <div class="title">邦伲德商城助力企业抓住风口 轻松搭建3端商城</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/webpage/3.png" class="cardimg" />
          <div class="cardtext">树立企业公信力</div>
          <div class="cardinfo">
            <div>企业信誉档案覆盖上亿网民</div>
            <div class="text">权威机构认证网站身份</div>
            <div>一键获取企业可信档案</div>
          </div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/webpage/4.png" class="cardimg" />
          <div class="cardtext">提升品牌溢价</div>
          <div class="cardinfo">
            <div>将潜在客户转化为合作伙伴</div>
            <div class="text">提升网站流量和订单量</div>
            <div>主流浏览器展示网站信息</div>
          </div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/webpage/5.png" class="cardimg" />
          <div class="cardtext">保障网站安全</div>
          <div class="cardinfo">
            <div>防攻击、防病毒、防篡改</div>
            <div class="text">全方位实时监控漏洞</div>
            <div>帮企业防范钓鱼假冒网站</div>
          </div>
        </div>
      </div>
    </div>

    <div class="contertop scene">
      <div class="title">使用场景</div>
      <div class="title2">浏览器展示</div>
      <div class="scenelist">
        <div class="scenecard">
          <img src="../../assets/product/webpage/6.png" alt="" />
          <div class="cardtext">搜狗浏览器展示效果</div>
        </div>
        <div class="scenecard">
          <img src="../../assets/product/webpage/7.png" alt="" />
          <div class="cardtext">遨游浏览器展示效果</div>
        </div>
      </div>

      <div class="scenelist">
        <div class="scenecard">
          <img src="../../assets/product/webpage/8.png" alt="" />
          <div class="cardtext">UC浏览器展示效果</div>
        </div>
        <div class="scenecard">
          <img src="../../assets/product/webpage/9.png" alt="" />
          <div class="cardtext">114浏览器展示效果</div>
        </div>
      </div>
    </div>

    <div style="background: #fafafa" class="contertop exhibition">
      <div class="title">实地验证版页面展示</div>
      <div class="title2">验证信息</div>

      <div class="exhibitionlist">
        <div class="exhibitionimg">
          <img src="../../assets/product/webpage/10.png" alt="" />
        </div>
        <div class="exhibitioncard">
          <div>
            <div class="exhibitiontitle">电子标识：</div>
            <div><img src="../../assets/product/webpage/11.png" alt="" /></div>
          </div>

          <div class="exhibitionline">
            <div class="exhibitiontitle">验证页面分为三部分：</div>
            <div class="text">验证信息、服务信息、实景展示</div>
          </div>

          <div>
            <div class="exhibitiontitle">验证信息页：</div>
            <div class="text">验证资质、基本信息、安全信息、验证机构</div>
          </div>
        </div>
      </div>
    </div>

    <div class="information" style="background: #fff">
      <div class="title">他们都在用</div>
      <div class="informationimg">
        <img src="../../assets/product/webpage/12.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.exhibition {
  padding-top: 140px;
  .exhibitionlist {
    margin-top: 60px;
    padding-bottom: 140px;
  }
  .exhibitionimg {
    img {
      width: 542px;
    }
  }
  .exhibitioncard {
    margin-left: 53px;
    .exhibitionline {
      margin-top: 85px;
      margin-bottom: 159px;
    }
    img {
      width: 226px;
    }
    .text {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
    }
    .exhibitiontitle {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }
}

.exhibitionlist {
  display: flex;
  justify-content: center;
}

.scenelist {
  margin: auto;
  width: 55%;
  display: flex;
  justify-content: space-between;
  .scenecard {
    text-align: center;
    margin-top: 45px;
    img {
      width: 446px;
    }
    .cardtext {
      margin-top: 5px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
    }
  }
}

.perplex {
  .topandbottom {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .advantagelist {
    margin-top: 100px !important;
    .advantagecard {
      .advantageline {
        .cardimg {
          width: 16px;
          height: 16px;
          border-radius: 13px;
          border: 1px solid #f95561;
          vertical-align: top !important;
        }
        .advantagetitle {
          margin-top: -5px;
        }
      }
    }
    .advantageimg {
      img {
        width: 457px;
      }
    }
  }
}

.material {
  .BuildStationlist {
    .BuildStationcard {
      .cardinfo {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin-top: 16px;
        .text {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.BuildStationlist {
  margin: auto;
  margin-top: 60px;
  width: 55%;
  display: flex;
  justify-content: space-between;
  .BuildStationcard {
    text-align: center;
    .cardimg {
      width: 120px;
    }
    .cardtext {
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
    .cardinfo {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }
  }
}
</style>